import { createApp } from 'vue'
import App from './App.vue'

if (import.meta.env.MODE !== 'production') {
  window.search = window.search || {};
  // window.search.apiKey = 'key_d2v8AQzh2FO0CBCC'; // dev
  window.search.apiKey = 'key_h3FB52owC3VYSBVq'; // stage
  // window.search.apiKey = 'key_QJLvEWqfGxS5JkmY'; // prod
  window.search.recommender = window.search.recommender || {};

//   window.search.recommender["homepage-best-sellers"] = {
//       "display_name": "Best Sellers",
//       "pod_id": "homepage-best-sellers",
//       "pod_strategy": "best_sellers",
//       "product_card_components": "",
//       "product_image_style": "square",
//       "recommender_size": "15",
//       "view_all_link": "/womens-all-womens.html",
//       "recommender_style": "full",
//       "cloudinary_params": "q_auto:best,b_rgb:f3f3f3,h_250,w_178,f_auto/b_rgb:f3f3f3"
//   }

//   window.search.recommender["pdp-pairs-with"] = {
//     "display_name": "Best Sellers",
//     "pod_id": "homepage-best-sellers",
//     "pod_strategy": "best_sellers",
//     "product_card_components": "",
//     "product_image_style": "square",
//     "recommender_size": "15",
//     "view_all_link": "/womens-all-womens.html",
//     "recommender_style": "full",
//     "cloudinary_params": "q_auto:best,c_pad,b_rgb:f3f3f3,h_250,w_178,f_auto/b_rgb:f3f3f3"
// }

  // document.cookie = 'selected_swatch=' + encodeURIComponent(JSON.stringify({4700: '1315'})) + ";path=/";
  window.search.recommender["homepage-best-sellers"] = {
    "collection": "Cherokee Workwear Collection",
    "display_name": "Pairs With",
    "pod_id": "pdp-pairs-with",
    "pod_strategy": "complementary",
    "item_id": "701",
    "product_card_components": "name,price_range,special_price",
    // "product_image_style": "circle",
    "recommender_size": "10",
    "view_all_link": "/",
    "recommender_style": "full",
    "cloudinary_params": "q_auto:best,w_338,ar_5:7,f_auto",
    "filters": JSON.stringify({"color": "Charcoal"})
  }

  // window.search.recommender["pdp-pairs-with"] = {
  //   "collection": "Cherokee Workwear Collection",
  //   "display_name": "Pairs With",
  //   "pod_id": "pdp-pairs-with",
  //   "pod_strategy": "complementary",
  //   "item_id": "GRSP500",
  //   "product_card_components": "name,price_range,special_price",
  //   // "product_image_style": "circle",
  //   "recommender_size": "10",
  //   "view_all_link": "/",
  //   "recommender_style": "full",
  //   "cloudinary_params": "q_auto:best,b_rgb:f3f3f3,e_bgremoval:ffffff,h_250,c_pad,w_178,f_auto/b_rgb:f3f3f3",
  // }

  // window.search.recommender["pdp-bundles"] = {
  //   "display_name": "Bundle",
  //   "pod_id": "pdp-bundles",
  //   "pod_strategy": "bundles",
  //   "item_id": "4700",
  //   "product_card_components": "name,price_range,special_price",
  //   "product_image_style": "circle",
  //   "recommender_size": "10",
  //   "view_all_link": "/",
  //   "recommender_style": "full",
  // }

  // window.search.recommender["homepage-new-arrivals"] = {
  //   "display_name": "New Arrivals",
  //   "pod_id": "homepage-new-arrivals",
  //   "pod_strategy": "filtered",
  //   "product_card_components": "name,price_range,special_price,review_stars",
  //   "product_image_style": "square",
  //   "recommender_size": "5",
  //   "view_all_link": "/",
  //   "recommender_style": "full",
  //   "filters": JSON.stringify({"color": "Red"})
  // }

  // window.search.recommender["cart-custom"] = {
  //   "display_name": "Matt Test Four",
  //   "pod_id": "cart-custom",
  //   "pod_strategy": "complementary",
  //   "item_id": JSON.stringify(['TF708CH', 'CK110A']),
  //   "product_card_components": "",
  //   "product_image_style": "square",
  //   "recommender_size": "9",
  //   "view_all_link": "",
  //   "recommender_style": "small",
  //   "cloudinary_params": "q_auto:best,h_75,c_pad,w_55,f_auto/b_rgb:fff",
  // }
}

for (const [pod_id, props] of Object.entries(window.search.recommender)) {
  createApp(App, props).mount('#recommender-' + pod_id);
}
